:root {
  font-size: calc(0.5em + 1vw);
  --amplify-primary-color:  #7fb0da;
}

body {
  background-color: #7fb0da;
}
.flyout {
  display:flex;
  flex-direction: column;
  min-height:100vh;
  justify-content: space-between;
}

.home-feature-box .fa {
  font-size:6rem;
}

.home-feature-box span {
  display: block;
  color:#111;
  font-weight:bold;
  margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
  border-bottom:0;
}

.example-components-list li > a .fa {
  color:rgba(0,0,0,.35);
  float:right;
}
