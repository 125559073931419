.Button__button___vS7Mv {
    --button-background-color: #7fb0da;
}

.Nav__nav___2Dx2Y {
    padding: 10px;
    background-color: #7fb0da;
}

.Nav__navItem___1LtFQ {
    display: inline-block;
    padding: 10px 5px;
    line-height: 19px;
    margin-right: 11px;
    color: white;
}