.appointment {
    background-color: #48658f;
    color: white;
    margin: 1% 18%;
    padding: 1%;
}
a:link {
    color: white;
}
a:visited{
    color: pink;
}

.fightVirus {
    font-size: 0.9rem;
    color: darkorange;
    margin-bottom: 1px;
}

.specialPrice {
    color: darkorange;
    margin-bottom: 1px;
}

.discountPeriod {
    font-size: 0.9rem;
    color: darkorange;
    margin-top: 1px;
}

.sign-out-btn {
    font-size: 0.5rem;
    position: absolute;
    color: white;
    margin: -5% 58% 50% 85%
}