.slogan {

}

.beautiful-life {
    position: absolute;
    top: 78%;
    left: 7%;
    font-family: "Sacramento";
    font-size: 4.0rem;
    font-weight: 100;
    color: #fff;
    text-align: center;
    word-spacing: 1rem;
}

.healing {
    position: absolute;
    top: 28%;
    left: 20%;
    font-family: "Sacramento";
    font-size: 3.5rem;
    font-weight: 100;
    color: #fff;
    text-align: center;
    word-spacing: 0.5rem;
}

.beautiful {
    position: absolute;
    top: 90%;
    left: 20%;
    font-family: "Sacramento";
    font-size: 5rem;
    font-weight: 100;
    color: #fff;
    text-align: center;
    word-spacing: 1rem;
}

.life {
    position: absolute;
    top: 90%;
    left: 60%;
    font-family: "Sacramento";
    font-size: 5rem;
    font-weight: 100;
    color: #fff;
    text-align: center;
    word-spacing: 1rem;
}
