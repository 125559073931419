.aboutme {
    background-color: #112b51;
    color: #fff;
    margin: 2% 15%;
    padding: 1em;
}
.bodycode-cert {
    margin-top: 10%;
    margin-left: 16%;
}
.nd-cert {
    margin-top: 10%;
    margin-left: 8%
}
.emotioncode-cert {
    margin-top: 10%;
    margin-left: 8%;
}

.center-word {
     text-align: center;
     font-family: "Apple Chancery";
     font-size: 2rem;
 }
.center-word-small {
    text-align: center;
    font-family: "Apple Chancery";
    font-size: 1rem;
}
.images {
    margin-bottom: 1.5em;
    zoom: 0.8;
}
.become-bc-yourself {
    background-color: #112b51;
    color: #fff;
    margin: 4% 15%;
    padding: 1em;
    font-size: 1rem;
}
.energy-healing {
    padding: 0.6em;
    font-size: 1.5rem;
}
